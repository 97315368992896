*{
  box-sizing:border-box;
}
$dark-blue: #052354;
.App {
  text-align: center;
}
.white{
  color:#FFF;
}
.large-font{
  font-size:3em;
}
.main-title{
  padding-top:40px;
}
.subtitle{
  font-size:calc( 10px + 2vmin);
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  background-color:white;
  border-radius:50%;
  border: 10px white solid;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/
.header-wrapper{background-image: url("image/header-background.png");background-repeat:round;}
.App-header {
background: rgb(0,48,143);
background: linear-gradient(74deg, rgba(0,48,143,1) 5%, rgba(43,144,228,0.6) 72%, rgba(155,203,245,0.6) 100%);
//background-color:#1d90ee;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Nav-link{ width:15%;height:100%;margin-top:10px; margin-bottom:10px;

  color:white;           //rgb(27, 26, 26);
  background-color: transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  font-size:18px;
  font-weight:bold;
  font-family: 'Roboto', Arial;
  text-decoration: none;
}
.Nav-link:hover{
  color:#61dafb;
}
#mobile-nav{display:none;margin-top:-7px;background-color:#0f548d;padding:0.5rem;}
.hamburger-menu{display:inline-block;padding-right:20px;padding-top:15px;}

.logo-name{
float:left;
font-weight:bold;
font-size:24px;
color:white;
list-style-type: none;
margin: 0 0 0 20px;
padding-right:10px;
}
.logo-name-footer{
  font-size:32px;
  font-weight:bold;
  padding-left:20px;
  margin-top:0px;
  padding-top:20px;
}

.Nav-item{
  list-style-type: none;
  margin: 0;
  padding-right:10px;
  display:inline-block;
}

.mobile-nav-item{
  list-style-type: none;
  margin: 0;
  padding-right:10px;
  display:inline-block;
}

nav{text-align:right;
  height:60px;
  background-color: transparent;
}

#nav-wrapper{
background-color:rgba(77, 77, 77, 0.3);
width:100%;
height:60px;
position:absolute;
z-index:1;
}

// About \\

.about-section{
  padding-top:140px;
  background-color:#052354;
  margin-bottom:-5px;
  overflow: hidden;
}
#about-paragraph{
  margin:0 auto 30px;
  max-width:1000px;
   line-height:1.5; 
   font-size:calc(10px + 1vmin);  
}
.wave{width:110%;height:250px;margin-left:-20px;overflow:hidden;}
.wave-skill{background-color:#0f548d;margin-bottom:-5px;}

// Start Projects \\

#projects{background-color:#0f548d;}
#projects-wrapper{
  width:100%;
  display:flex;
  flex-direction: row;
}
/*.project-header{
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
  @keyframes tracking-in-expand{0%{letter-spacing:-.5em;opacity:0}40%{opacity:.6}100%{opacity:1}}
*/
.projects-item{
  width:33.333%;
  height:300px;
  background-color: #0f548d;
  //#00689A
  padding:1rem;
}
.projects-image{
  height:80%;
  margin-bottom:5px;
}


.projects-link{
color:white;
font-family:"Roboto", Arial;
font-weight:bold;
font-size:18px;
text-decoration: none;
display:block;
}
.projects-link:hover{
color:#61dafb;
}

.uni-link{
  color:white;
  font-family:"Roboto", Arial;
  font-weight:bold;
  font-size:18px;
  text-decoration: none;
  display:block;
  margin-top:0;
  margin-bottom:5px;
}

// Start Skills \\
//$skill-color: #1d90ee;
$skill-color: #0f548d;


#skills{  
  background-color:$dark-blue;
  height:650px;
  overflow:hidden;
}
.skill-wrapper{
  background-color:$dark-blue;
  padding-top:50px;
  padding-bottom:50px;
  height:650px;
  overflow:hidden;
}

.skill-bar-container{
  position:relative;
  width:60%;
  background-color:#2667a5;
  //background-color:#eee;
  margin: 0 auto;
}
.skill-bar{
    display:block;
    position:relative;
    width:100%;
    background: #eee;
    height:35px;
    border-radius:4px;
    -moz-border-radius:4px;
    -webkit-border-radius:4px;
    height:25px;
    margin-bottom:5px;
      &.skill-1 { width:100%; background-color: $skill-color;animation-name: progress-animation-skill1;animation-duration:3s;}
      &.skill-2 { width:80%; background-color: $skill-color;animation-name: progress-animation-skill2;animation-duration:3s;}
      &.skill-3 { width:75%; background-color: $skill-color;animation-name: progress-animation-skill3;animation-duration:3s;}
      &.skill-4 { width:60%; background-color: $skill-color;animation-name: progress-animation-skill4;animation-duration:3s;}
      &.skill-5 { width:30%; background-color: $skill-color;animation-name: progress-animation-skill5;animation-duration:3s;}
}
  .skill-bar-title{
    position: absolute;
    left: 0px;
    background:#6adcfa;
    width:100px;
    z-index:1;
    height:25px;
    font-weight:bold;
  }
  .skillbar-title span {
    display:block;
    height:35px;
    line-height:35px;
    background:rgba(0,0,0,0.1);
    padding:0 20px;
  }
  .skill-value{
    position: absolute;
      right: 10px;
      color: #ffffff;
      z-index:1;
  }

  #skill-image-drawn{
    height:300px;
    background-image:url(./image/site-image2.png);
    background-position:bottom;
  }
    
  .contact{
    width:100%;
    height:600px;
    background-color:#0f548d;
    padding-top:20px;
  }

  
 form{width:30%;margin:0 auto;}
 input{background-color:$dark-blue;color:white;width:100%;border:0;padding:1rem;margin-bottom:3px;outline:none;}
 textarea{background-color:$dark-blue;color:white;width:100%;max-width:100%;min-width:100%;border:0;padding:1rem;margin-bottom:3px;outline:none;min-height:150px; box-sizing: border-box;max-height:300px; }
 #submit{cursor:pointer;}
 footer{
  height:200px;
  background-color: $dark-blue;
  color:white;
  }
  .footer-logo{
    width: 50%;
    display:inline-block;
  }

// Keyframes \\
@keyframes App-logo-spin { from {transform: rotate(0deg);}to { transform: rotate(360deg);}}
@keyframes progress-animation-skill1{from{width:0%}to{width:100%}}
@keyframes progress-animation-skill2{from{width:0%}to{width:80%}}
@keyframes progress-animation-skill3{from{width:0%}to{width:75%}}
@keyframes progress-animation-skill4{from{width:0%}to{width:60%}}
@keyframes progress-animation-skill5{from{width:0%}to{width:30%}}

// Media Queries \\
@media (max-width:1500px){
  .header-wrapper{background-repeat:no-repeat;}
}

@media (max-width:1380px){
  .projects-image{width:100%;}
  #contact-form{width:40%;}
}

@media(max-width:1200px){
  .skill-bar-container{width:70%;}
}
@media (max-width:1050px){
  #projects-wrapper{display:inline-block; flex-direction:unset;}
  .projects-item{width:100%;}
  .projects-image{width:auto;}
  #about-paragraph{font-size: calc(8px + 2vmin);}

}
@media (max-width:980px){
  .skill-bar-container{width:80%;}
  #contact-form{width:50%;}
}
@media (min-width:980px){
  #nav-wrapper{position:fixed;}
}
@media (min-width:820px){
  nav{width:auto;position: relative;}
}

@media(max-width:720px){
  .skill-bar-container{width:100%;}
  .wave{height:100px;}
  #contact-form{width:60%;}
  #main-nav{display:none;}
  .mobile-nav-item{display:block;text-align:center;background-color:#0f548d;margin-bottom:10px;}
  .logo-name{margin-bottom:0px;display:block;padding-left:15px;margin-top:20px;}
  ul{padding-left:0px;}
  #skill-image-drawn{   
   overflow:hidden;
    width:1315px;
    background-position-x:right;}
}
@media (min-width:720px){
  .hamburger-menu{display:none;}
  #mobile-nav{display:none;}
}
@media(max-width:540px){
  #contact-form{width:100%;}
}
@media(max-width:475px){
  .projects-image{width:365px;}
  #skill-image-drawn{width:1192px;}
}

// Media queries for other mobile devices (e.g. Galaxy S5, Pixel 2) \\ 
@media (max-width:420px){
  #projects-wrapper{display:inline-block; flex-direction:unset;}
  #projects{padding-top:20px;}
  .projects-item{width:100%;}
  .projects-image{width:350px;}
  .about-section{padding-top:60px;}
  .wave{height:70px;}  
  .logo-name{text-align:center;}
  #mobile-nav{padding-top:0px;}
}
@media (max-width:400px){.projects-image{width:330px;}}
@media (max-width:375px){.projects-image{width:280px;}#skill-image-drawn{display:none;}}
@media (max-width:325px){.projects-image{width:270px;}}

// Media queries for small mobile devices (e.g. iPhone 5/SE) \\
@media (max-width:320px){
  .projects-item{width:100%;padding-left:12px;}
}
@media(max-width:280px){
  .logo-name{font-size:22px;}
}





@font-face {font-family: 'Roboto'; src: url('/fonts/Roboto-Regular.ttf') format('truetype'); font-weight: normal;font-style: normal;}
@font-face {font-family: 'Roboto'; src: url('/fonts/Roboto-Italic.ttf') format('truetype'); font-weight: normal; font-style: italic;}
@font-face {font-family: 'Roboto'; src: url('/fonts/Roboto-Bold.ttf') format('truetype'); font-weight: bold; font-style: normal;}
@font-face {font-family: 'Roboto'; src: url('/fonts/Roboto-BoldItalic.ttf') format('truetype'); font-weight: bold; font-style: italic;}
@font-face {font-family: 'Roboto'; src: url('/fonts/Roboto-Medium.ttf') format('truetype'); font-weight: 300; font-style: normal;}
@font-face {font-family: 'Roboto'; src: url('/fonts/Roboto-MediumItalic.ttf') format('truetype'); font-weight: 300; font-style: italic;}
@font-face {font-family: 'Roboto'; src: url('/fonts/Roboto-Thin.ttf') format('truetype'); font-weight: 200; font-style: normal;}
@font-face {font-family: 'Roboto'; src: url('/fonts/Roboto-ThinItalic.ttf') format('truetype'); font-weight: 200; font-style: italic;}
@font-face {font-family: 'Roboto'; src: url('/fonts/Roboto-Light.ttf') format('truetype'); font-weight: 100; font-style: normal;}
@font-face {font-family: 'Roboto'; src: url('/fonts/Roboto-LightItalic.ttf') format('truetype'); font-weight: 100; font-style: italic;}



$normal-font: -apple-system, BlinkMacSystemFont, 'Roboto', Arial;

body {
  margin: 0;
  font-family: $normal-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
